@import "../../app.scss";


.sidebar{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: white;
    color: black;


    .bg{
        position: fixed;
        top:0;
        left: 0;
        bottom: 0;
        width: 400px;
        background: white;
        z-index: 999;

        @include mobile{
            width: 200px;
        }

        .links{
            position: absolute;
            width: 100%;
            height: 800px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap:20px;
            user-select: none;

            a{
                
                font-size: 40px;
                @include mobile{
                    font-size: 20px;
                }
            }

        }
        button{
            z-index: 100;
            position: fixed;
            width: 50px;
            height: 50px;
            border-radius: 50%;
            position: fixed;
            top: 25px;
            left: 25px;
            background-color: transparent;
            border: none;
            cursor: pointer;
        }
    }
  

}

