*{
    padding:0;
    margin:0;
    box-sizing: border-box;
    font-family: "DM Sans", sans-serif;
}
body{
    background-color: #0c0c1d;
    color: lightgray;
}

html{
    scroll-snap-type: y mandatory;
    scroll-behavior: smooth;
}

section{
    height: 100vh;
    scroll-snap-align: center;
    width: 100%;
}

a{
    text-decoration: none;
    color:inherit;
}
.spatiu{
    height: 250px;
    width: 100%;
    margin-right: 0;
    background: #111132;
    
}

@mixin mobile{
    @media(max-width:738px){
        @content;
    }
}
@mixin tablet{
    @media(max-width:1024px){
        @content;
    }
}
@mixin desktop{
    @media(max-width:1366px){
        @content;
    }
}