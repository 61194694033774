

.cicatrici{
    background-image: url("./cicatrici2.png");
    background-size:cover ;
    background-position: bottom;
    width: 100%;
    height: 110%;
    position: absolute;


     @media only screen and (max-width: 1280px) {
        background-image: url("./cicatrici3.png");
        width: 1280px;
        height: 120vh;
     }
     @media only screen and (max-width: 500px) {
        background-image: url("./cicatriciposter.png");
        background-position: bottom;
        height: 52%;
        width: 352px;
        margin-left: 10px;
        margin-top: 130px;
        border-radius: 20px;

     }
}
.fullscreen{
display: none;
@media only screen and (max-width: 500px) {
    display: flex;
    margin-top: 32rem;
    font-size: 23px;
}
}