@import "../../app.scss";

.navbar{

    height:100px;

    .wrapper{
        display: flex;
        align-items: center;
        justify-content: space-between;
        max-width: 1366px;
        margin: auto;
        height: 100%;
@include mobile{
    justify-content: flex-end;
    padding:20px;
}

        span{
            font-weight: bold;
            
            @include mobile{
                display: none;
            }
            @media only screen and (max-width: 1080px) {
               margin-left: 10%;
            }
            @media only screen and (max-width: 1280px) {
                margin-left: 10%;
             }
        }
    
        .links{
            display: flex;
            gap: 20px;
            background: transparent;
            margin-right: -500px;
            p{
                margin-left: -25px;
            }
            @media only screen and (max-width: 1080px) {
                margin-right: 2%;
           
             }
             @media only screen and (max-width: 1280px) {
                margin-right: 2%;
             }
            img{
                width: 38px;
                height: 38px;
                background: rgba(90, 97, 26, 0.418);
                border-radius: 30px;
            
            }
        }

    }


}