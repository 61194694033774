@import "../app.scss";


.portofoliu{
    position:relative;
    

    .progres{
        position:sticky;
        top:0;
        left:0;
        padding-top: 50px;
        text-align: center;
        color:orange;
        font-size: 36px;
  
@include mobile{
    padding-top: calc(100vh - 100px) ;
    font-size: 24px;
}
        .progresBar{
            height: 10px;
            background-color: white;
        }
    }
  
    .container{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        overflow: hidden;
        
        .pozitionare{
            max-width: 1366px;
            height: 100%;
            display: flex;
            gap: 50px;
            margin: auto;
            align-items: center;
            justify-content: center;
            gap:50px;
 
 
@include mobile{
    flex-direction: column;
}

  .imageContainer{
    flex:1;

    @media only screen and (max-width: 1080px) {
        width: 80%;
        max-height: 300px;
     }
     @media only screen and (max-width: 1280px) {
        width: 80%;
        max-height: 300px;
     }
     @include mobile{
        width: 95%;
        margin-left: -37px;
        max-height: 300px;
    
    }
    
    video{
        width:100;
        height:500px;
        object-fit: cover;
        border-radius:20px;

        @include mobile{
            width: 90%;
            height: 90%;
            object-fit: contain;
            margin-top: 20%;
            margin-left: 20px;
            border-radius:10px;
        }
        @media only screen and (max-width: 1080px) {
            width: 100%;
            max-height: 350px;
            margin-left: 20px;
         }
         @media only screen and (max-width: 1280px) {
            width: 100%;
            max-height: 350px;
            margin-left: 20px;
         }
        
    }


  }

    
        .textContainer{
            flex:1;
            display: flex;
            flex-direction: column;
            gap:30px;

            @include mobile{
                transform: none !important;
                text-align: center;
                padding: 10px;
                align-items: center;
            }

            h2{
               font-size: 72px; 

               @include mobile{
                font-size: 36px;
               }
            }
            p{
                color: gray;
                font-size: 20px;

                @include mobile{
                    font-size: 16px;
                   }
            }

            button{
                background-color: orange;
                border-radius: 10px;
                padding: 10px;
                width: 200px;
                cursor: pointer;
            }


        }
    }
}}