@import"../../app.scss";

.contact{
 
    height: 100%;
    max-width: 1366px;
    margin: auto;
    display: flex;
    align-items: center;

    @include mobile{
        width: 100%;
        padding: 0px;
        flex-direction: column;
    }

.textContainer{
    flex:1;
    flex-direction: column;
    gap: 40px;
    @include mobile{
        gap: 20px;
        text-align: center;
        align-items: center;
        margin-top: 50px ;
    }
    h1{
        font-size: 100px;
        line-height: 88px;
        margin-left: 5px;

        @include mobile{
            font-size: 36px ;
        }
    }
    span{
        font-weight: 100px;
    }
    h6{
        display: flex;
        justify-content: start;
        font-size: 18px;
        @include mobile{
            justify-content: center;
        }
    }
    .text1{
        display: flex;
        margin-right:-25px ;
        @include mobile{
            margin:0px;
            text-align: center;
        }
    }
    .text2{
        display: flex;
        margin-right:-45px ;
        @include mobile{
            margin:0px;
        }
    }
    .text3{
        display: flex;
        margin-right:-145px ;
        @include mobile{
            margin: 0;
        }

    }
  .logo{
    background-color: #1a1a3f;
    border-radius: 10px;
    margin-right: 15px;
    margin-left: 15px;
    margin-top: 20px;
    border:1px solid orange;
    width: 70px;
    height: 70px;

    @include mobile{
        margin: 0;
        white-space: initial;

        
    }
    
  }


  h2{
    font-size: small;
  }

  
    }
}
.formContainer{
    flex:1;
    position: relative;
    
    @include mobile{
        width: 100%;
        padding-left: 50px;
        padding-right: 50px;
    }

    .phoneSvg{
        position: absolute;
        stroke:orange;
        margin: auto;
        z-index: -1;

    }

    form{
    flex:1;
    display: flex;
    flex-direction: column;
    gap:20px;
 
    input, textarea{
        padding: 20px;
        background-color: transparent;
        border:1px solid white;
        color:white;
        border-radius: 5px;
        @include mobile{
            padding: 10px;
        }

    }
    button{
        padding: 30px;
        border-radius: 30px;
        background-color: orange;
        cursor: pointer;
        font-weight: 500px;
        font-size: 20px;

        @include mobile{
            padding: 10px;
            font-size: 12px;
        }
    }



}
@include mobile{
 
    .imagesvg{
height: 200px;
width: 200px;
align-self: center;
        
    }
    
}


}
