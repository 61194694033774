@import"../../app.scss";

.profil{
    height: calc(100vh - 100px);
    overflow: hidden;
    background: linear-gradient(180deg, #0c0c1d,#111132);
    position: relative;
    z-index: -1;
    

    .pozitionare{
        display: flex;
        align-items: center;
        justify-content: space-between;
        max-width: 1366px;
        margin: auto;
        height: 100%;
        @media only screen and (max-width: 1080px) {
            margin-left: 10%;
         }
         @media only screen and (max-width: 1280px) {
            margin-left: 10%;
         }
    
    }

    .imageContainer{
        height: 100%;
        position:absolute;
        top:0;
        right: 25%;
        z-index:0;
        user-select: none;

    
    .border{
        animation: img 4s ease-in-out infinite;
        border-radius: 60% 40% 30% 70%/60% 30% 70% 40%;
        border:1px solid rgba(255, 0, 0, 0);
        width: 500px;
        position: absolute;
        top: 0;
        right: -15rem;
        object-fit: cover;
        @media only screen and (max-width: 1080px) {
            width: 400px;
         }
         @media only screen and (max-width: 1280px) {
            width: 400px;
         }

        @include mobile{
            top:2%;
            bottom:190px;
            width: 250px;
            right: -30px;
        }
    } 
    @keyframes img{
        0%{
            border-radius: 60% 40% 30% 70%/60% 30% 70% 40%;
        }
        50%{
            border-radius: 30% 60% 70% 40%/50% 60% 30% 60%
        }
        100%{
            border-radius: 60% 40% 30% 70%/60% 30% 70% 40%;
        }
      }   
    }

    button{
        padding: 20px;
        border: 1px solid white;
        border-radius: 10px;
        background-color: transparent;
        color:#ffffff;
        margin-right: 20px;
        user-select: none;


    }


    .textContainer{
        display: flex;
        position: relative;
        flex-direction: column;
        width: 50%;
        height: 50%;
        gap:40px;
        margin-top: 120px;

        @include mobile{
            height: 1%;
            width:100%;
            gap:20px;
            align-items:center;
            text-align: center;
        }

    }
    h1{
        font-size: 88px;
        
        @include mobile{
           
            font-size: 36px;
   
        }
    }
    h2{
        letter-spacing: 5px;
        color:rebeccapurple;
        @include mobile{
   
            margin-top: -110px;
        }
    }
    .slidingText{
        position: absolute;
        font-size: 50vh;
        bottom: -120px;
        white-space: nowrap;
        color:#ffffff09;
        width: 50%;
        font-weight: bold;
        user-select: none;
    }

}
