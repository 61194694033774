@import"../../app.scss";


.prezentare{
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    h1{
        font-size: 100px;
        @include mobile{
            font-size: 62px;
           text-align: center;
        }

    }
    .munti{
        background-image: url("./mountains.png");
        background-size:cover ;
        background-position: bottom;
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 3;
        @include mobile{
            background-size: contain;
            background-repeat: no-repeat;
        }
    }
    .planete{
        background-image: url("./planets.png");
        background-size:cover ;
        background-position: bottom;
        width: 100%;
        height: 80%;
        top:0;
        position: absolute;
        z-index: 1;
        @include mobile{
            background-size: contain;
            background-repeat: no-repeat;
        }
    }

    .stele{
        background-image: url("./stars.png");
        background-size:cover ;
        background-position: bottom;
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 1;
    }

   }
